import { defineStore } from 'pinia';

export const customerStore = defineStore('customer', {
  state: () => {
    return {
      deposits: [],
    };
  },
  actions: {
    set({ deposits }) {
      this.deposits = deposits;
    },
    reset() {
      this.deposits = [];
    }
  }
});
