import { onboardingStore } from '@/stores/onboarding';
import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/apply',
    component: () => import('@/components/onboarding'),
    beforeEnter: async (to, from, next) => {
      const onboarding = onboardingStore();
      onboarding.getApplicationDetails().then(next);
    },
    children: [
      {
        path: 'qualification',
        name: 'Qualification',
        component: () =>
          import('@/components/onboarding/qualification-question.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: '/apply',
        beforeEnter: async (to, from, next) => {
          const onboarding = onboardingStore();
          await onboarding.getApplicationDetails();
          const nextRoute = applicationStage(onboarding.applicationData);
          next(nextRoute);
        }
      },
      {
        path: 'rejection',
        name: 'Rejection',
        component: () =>
          import('@/components/onboarding/rejected-qualification.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'plaid-verification',
        name: 'Plaid Verification',
        component: () => import('@/components/onboarding/plaid-idv.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'business-details',
        name: 'Business Details',
        component: () =>
          import('@/components/onboarding/business-details/index.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'terms-and-conditions',
        name: 'Terms and Conditions',
        component: () =>
          import('@/components/onboarding/terms-and-conditions.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'onboarding-activation',
        name: 'Onboarding Activation',
        component: () =>
          import('@/components/onboarding/onboarding-activation.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'approved-application',
        name: 'Approved Application',
        component: () =>
          import('@/components/onboarding/account-activated-modal.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'rejected-application',
        name: 'Rejected Application',
        component: () =>
          import('@/components/onboarding/rejected-application.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      }
    ]
  }
];
function applicationStage(application) {
  if (!application) {
    return '/apply/qualification';
  }

  if (application.application?.type == null) {
    return '/apply/qualification';
  }

  if (application.application?.hasConsented) {
    return '/apply/onboarding-activation';
  }

  if (application.business?.administrator.kycStatus === 'success') {
    return '/apply/business-details';
  }

  return '/apply/qualification';
}
