import { defineStore } from 'pinia';
import { disposeClient } from '@/utils/flags'

export const authStore = defineStore('auth', {
  state: () => {
    return {
      user: null,
      isAuthenticated: false,
      isLoaded: false
    };
  },
  actions: {
    setUser(user, isAuthenticated) {
      this.user = user;
      this.isAuthenticated = isAuthenticated;
      this.isLoaded = true;
    },
    resetUser() {
      disposeClient();
      this.user = null;
      this.isAuthenticated = false;
      this.isLoaded = false;
    }
  }
});
