export default [
  {
    path: '/card/temp-details',
    name: 'CardDetails',
    component: () => import('@/views/temp-links/show-reward-card-details.vue'),
    meta: { title: 'CardDetails', protected: true },
    props: (route) => ({
      temp_url: route.query.temp_url
    })
  }
];
