import math from '@/utils/math.js';

export default {
  remapIntervalRewards: (resp) => {
    return {
      ...resp,
      data: resp.data.map((tx) => {
        return {
          ...tx,
          rewards: remapRewards(tx.rewards)
        }
      })
    }
  },

  remapPendingRewards: (resp) => {
    return {
      ...resp,
      data: remapRewards(resp.data)
    };
  }
}

export const REWARD_RULE_STATUS = {
  EARNED: 'earned',
  ACCRUED: 'accrued',
  EARNED_MATURED: 'earned-matured',
}

const remapRewards = (rewards) => {
  rewards.rules = rewards.rules.map((rule) => {
    rule.accrualLessFee = math.roundNumber(rule.accrual - rule.fee, 2);
    rule.status = getRewardStatus(rule);
    rule.earnedNetUI = getEarnedAmountUI(rule);
    rule.accrualUI = getAccruedAmountUI(rule);
    return rule;
  })
  rewards.totals = {
    ...rewards.totals,
    earnedNetUI: rewards.rules.reduce((acc, rule) => acc + rule.earnedNetUI, 0),
    accrualUI: rewards.rules.reduce((acc, rule) => acc + rule.accrualUI, 0)
  }
  return rewards;  
}

const getRewardStatus = (rule) => {
  if (rule.earnedNet === rule.accrualLessFee) {
    return REWARD_RULE_STATUS.EARNED;
  }
  if (rule.earnedNet < rule.accrualLessFee) {
    return REWARD_RULE_STATUS.ACCRUED;
  }
  return REWARD_RULE_STATUS.EARNED_MATURED;
}

const getEarnedAmountUI = (rule) => {
  if (rule.status === REWARD_RULE_STATUS.EARNED || rule.status === REWARD_RULE_STATUS.EARNED_MATURED) {
    return rule.earnedNet;
  }
  if (rule.status === REWARD_RULE_STATUS.ACCRUED) {
    return 0;
  }
  throw new Error(`Invalid rule status: ${rule.status}`);
}

const getAccruedAmountUI = (rule) => {
  if (rule.status === REWARD_RULE_STATUS.EARNED) {
    return 0;
  }
  if (rule.status === REWARD_RULE_STATUS.ACCRUED || rule.status === REWARD_RULE_STATUS.EARNED_MATURED) {
    return rule.accrualLessFee;
  }
  throw new Error(`Invalid rule status: ${rule.status}`);
}