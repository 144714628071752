import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/card',
    name: 'Card',
    meta: {
      protected: true
    },
    component: () => import('@/components/adcard'),
    children: [
      {
        path: '/',
        name: 'CardDashboard',
        component: () => import('@/components/adcard/adcard-dashboard.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: '/card',
        redirect: '/'
      },
      {
        path: 'cards',
        name: 'Cards',
        component: () => import('@/components/adcard/adcard-cards.vue'),
        meta: {
          title: `Cards - ${domainTitle}`
        }
      },
      {
        path: 'transactions',
        name: 'CardTransactions',
        component: () => import('@/components/adcard/adcard-transactions.vue'),
        meta: {
          title: `Transactions - ${domainTitle}`
        }
      },
      {
        path: 'payments',
        name: 'CardPayments',
        component: () => import('@/components/adcard/lithic/payments/payment-index.vue'),

        meta: {
          title: `Payments - ${domainTitle}`
        }
      },
      {
        path: 'statements',
        name: 'CardStatements',
        component: () => import('@/components/adcard/e6/payments/payment-index.vue'),
        meta: {
          title: `Payments - ${domainTitle}`
        },
        children: [
          {
            path: 'past',
            name: 'PastPayments',
            component: () => import('@/components/adcard/e6/payments/payment-past.vue'),
            meta: {
              title: `Payments - ${domainTitle}`
            }
          },
          {
            path: 'scheduled',
            name: 'ScheduledPayments',
            component: () => import('@/components/adcard/e6/payments/payment-scheduled.vue'),
            meta: {
              title: `Payments - ${domainTitle}`
            }
          },
          {
            path: '',
            redirect: 'past'
          },
        ],
      },
      // {
      //   path: 'make-payment',
      //   name: 'MakePayment',
      //   component: () => import('@/components/adcard/e6/payments/make-payment.vue'),
      //   meta: {
      //     title: `Payments - ${domainTitle}`
      //   }
      // },
      {
        path: 'insights',
        name: 'CardInsights',
        component: () => import('@/components/adcard/adcard-insights.vue'),
        meta: {
          title: `Insights - ${domainTitle}`
        }
      },
      {
        path: 'rewards',
        name: 'CardRewards',
        redirect: 'rewards/overview',
        component: () =>
          import('@/components/adcard/rewards/rewards-index.vue'),
        meta: {
          title: `Rewards - ${domainTitle}`,
          ownerOnly: true
        },
        children: [
          {
            path: 'overview',
            name: 'RewardsOverview',
            component: () => import('@/components/adcard/rewards/overview-tab.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'cards',
            name: 'RewardsCards',
            component: () => import('@/components/adcard/rewards/rewards-cards-and-transactions.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'accrued',
            name: 'RewardsAccrued',
            component: () => import('@/components/adcard/rewards/accrued-rewards.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'terms',
            name: 'RewardsTerms',
            component: () => import('@/components/adcard/rewards/rewards-terms.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'redemption',
            name: 'RewardsRedemption',
            component: () => import('@/components/adcard/rewards/rewards-wallet.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'earnings',
            name: 'RewardsEarning',
            component: () => import('@/components/adcard/rewards/rewards-payouts-summary.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          }
        ]
      }
    ]
  }
];
