import { defineStore } from 'pinia';
import api from '@/utils/api-services';
import { notifyStore } from '@/stores/notification';

export const onboardingStore = defineStore('onboarding', {
  state: () => ({
    applicationData: null,
    loading: false,
    loaded: false,
  }),
  actions: {
    async getApplicationDetails() {
      const notify = notifyStore();
      try {
        const {data} = await api.getApplicationDetails();
        this.applicationData = data;
        this.loaded = true;
      } catch (error) {
        notify.showNotification(
          'Your application could not be loaded',
          'error'
        );
      }
    },
  }
});
